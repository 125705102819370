
export const services = [
    {
        id: 1,
        name: "Environmental Engineering",
        info: "Our services include Air Pollution Control, Solid Waste Management, Wastewater Treatment, and Drinking Water Production. If your company is facing an environmental concern or risk, we can help.",
        publish: "publish",    
    },
      {
        id: 2,
        name: "Environmental Studies",
        info: "Whether you're looking for an environmental impact assessment, environmental management plan, environmental annual report, environmental planning, project development, or research and training, we can help.",
        publish: "draft",
      },
]